import React, { Component, Suspense } from "react";

import PARAMS from "utils/PARAMS";
import * as API from "utils/API_V2";
import GridContainer from "components/Grid/GridContainer";
import Loader from "react-spinners/RingLoader";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import rutas from "routes.js";
import { createGlobalStyle } from 'styled-components';

// core components
import { createBrowserHistory } from "history";
import NotFound from './NotFound';

import morganiteMedium from 'assets/font/Morganite-Medium.ttf';
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MorganiteMedium';
    src: url(${morganiteMedium});
  }
`;

const hist = createBrowserHistory();

class App extends Component {
  state = {
    apiToken: localStorage.getItem("apiToken") || "",
    user: localStorage.getItem("userType") || "entidad",
    admin: false,
    load: false,
    permiso: null
  };

  setApiToken = token => {
    this.setState({ apiToken: token });
  };

  componentDidMount(){
    this.setState({ load: true });
  }

  render() {
    if (this.state.load) {
      return (
        <>
          <GlobalStyle/>
          <Router history={hist}>
            <Suspense fallback={<GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
              <Loader color={PARAMS.firstColor} size={60} />
            </GridContainer>}>
              <Switch>
                {rutas.map((prop, key) => {
                  let Componente = prop.component;
                  return (
                    <Route
                      path={prop.path}
                      component={(props) => <Componente {...props}/>}
                      key={key}
                    />
                  );
                })}
                <Route component={ NotFound }/>
              </Switch>
            </Suspense>
          </Router>
        </>
      );
    }else{
      return(
        <GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
          <Loader color={PARAMS.firstColor} size={80} />
        </GridContainer>
      )
    }

  }
}

export default App;
