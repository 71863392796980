// import loginAdminImg from "assets/img/admin_img.jpg";
// import iconImg from "assets/img/logo_header.png";
// import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params['baseUrl'] = 'https://cabalcor.aicor.es/';
params['apiUrl'] = 'https://api.cabalcor.aicor.es/api/';
// params['loginAdminImg'] = loginAdminImg;
// params['sideBarIcon'] = iconImg;
// params['sideBarImg'] = sideBarImg;
// "purple", "blue", "green", "orange", "red", "black" ... modificar en material-dashboard-react.js
params['sideBarColor'] = 'black';
params['firstColorRGB'] = '44,42,56';
params['firstColor'] = '#2C2A38';
params['secondColor'] = '#D9D5F5';
params['color_green'] = '#7CC107';
params['color_red'] = '#FF0C56';
params['color_grey'] = '#BBBBBB';
params['proyectName'] = 'Aicor';
params['defaultTableLength'] = 10;
params['tableLengthArray'] = [10, 25, 50];
params['personalizacion'] = false;
params['dev_mode'] = true;

export default params;
