import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';
import * as fakeData from "utils/fakeData";
var fake = false;

// Instancia para api
var instance_api = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

// Global interceptor request
instance_api.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Global interceptor response
// instance_api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          instance_api.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    resolved.error = e;
  }

  return resolved;
}

export async function logout() {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.get('logout').then(res => res.data)
    )
  }
}

export async function getAforo() {
  return await resolve(
    instance_api.post(`sensorAforo/getAforo`).then(res => res.data)
  )
}
