import { lazy } from 'react';

const webRoutes = [
  {
    path: "/",
    component: lazy(() => import('views/LandingPage/LandingPage')),
  }
];

export default webRoutes;
